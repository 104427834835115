<template>
  <!-- <v-text-field
    v-if="quantityEditable"
    :key="`${itemModel.product_id}-${itemModel.warehouse_id}`"
    v-model="itemModel.stock"
    type="number"
    width="160px"
    hide-details="auto"
    dense
    class="mb-0i"
    outlined
    solo
    flat
    :rules="[
      function(val) {
        const regex = new RegExp('^\\d+$')
        if (!regex.test(val)) return 'Please enter in a numerical value for Quantity'
        if (Number.isNaN(val)) return 'Please enter in a numerical value for Quantity'
        if (val < 0) return 'You cannot assign negative inventory to a location.'

        return true
      }
    ]"
  /> -->
  <v-menu
    v-model="menu"
    :name="stock-edit-this.item.product_id"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="#000"
        plain
        small
        v-bind="attrs"
        v-on="on"
        class="font-weight-bold"
      >
        {{ item.stock }}
        <v-icon
          right
          dark
        >
          mdi-menu-down
        </v-icon>
      </v-btn>
    </template>

    <v-card>
        <form>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="changeValue"
                  label="Adjust by"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="newStockValue"
                  label="New Stock Value"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  label="Reason"
                  v-model="changeReason"
                  :items="changeReasons"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="saveStockChange()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'StockCell',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    changeReason: 'Correction',
    changeValue: 0,
    newStockValue: 0,
    changeReasons: [
      'Correction',
      'Count',
      'Recieved',
      'Return restock',
      'Damaged',
      'Theft or Loss',
      'Promotion or Donation'
    ]
  }),
  created: function () {
    this.newStockValue = this.item.stock
    console.log(this.item)
  },
  methods: {
    saveStockChange () {
      return this.$store
        .dispatch('inventory/createStockCount', {
          name: `Stock Adjustment for Product ${this.item.product_id} for Warehouse ${this.item.warehouse_name}`,
          description: 'Quick Stock Adjustment',
          products: [{
            assigned: true,
            ...this.item,
            id: this.item.product_id,
            status: 'unmatched',
            counted_stock: this.newStockValue
          }],
          warehouse_id: this.item.warehouse_id,
          reference: 'Quick Stock Adjustment',
          notes: this.changeReason
        })
        .then(data => {})
        .catch((responseError) => {
          console.error(responseError.statusText)
        })
        .finally(() => {
          this.menu = false
          this.changeValue = 0
          this.newStockValue = 0
          this.changeReason = 'Correction'

          this.$emit('reloadTable')
        })
    }
  },
  watch: {
    newStockValue (newValue, oldValue) {
      this.changeValue = newValue - this.item.stock
    }
  }
}
</script>
